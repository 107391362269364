import React from "react";
import "./homeBottomtext.scss";

function HomeBottomtext() {
  return (
    <div className="home-bottomtext-Container">
      <div id="homeBottomTitte">About Us</div>
      <div id="homeBottomP">
        Every project entrusted to us, places a young inner-city youth in a top
        tech company like Google, IBM, or SpaceX.{" "}
      </div>
    </div>
  );
}

export default HomeBottomtext;
