import React from "react";
import "./HomeServiceTitle.scss";

function HomeServiceTitle() {
  return (
    <div className="home-card-service">
      <div className="home-card-service-title">Our Services</div>
      <div className="home-card-service-content">
        We provide outstanding services and value to businesses for{" "}
        <span className="home-card-bold-content">software development,</span>{" "}
        and{" "}
        <span className="home-card-bold-content">educational consulting.</span>
      </div>
    </div>
  );
}

export default HomeServiceTitle;
