import React from "react";
import "./HeroImpactBanner.scss";

function HeroImpactBanner() {
  return (
    <div className="impact-banner">
      <div className="impact-banner-title">OUR Impact</div>
      <div className="impact-banner-subheading">
        We create a greater presence of Black and Brown professionals in top
        tech companies.
      </div>
    </div>
  );
}

export default HeroImpactBanner;
