import React, { useEffect, useState, useRef, useCallback } from "react";
import { useScroll } from "framer-motion";
import "./CardAnimationDeck.scss";
import HomeCard from "./LabCards";
import software from "../../../images/SoftwareConsulting.png";
import education from "../../../images/educationConsulting.png";

function CardAnimationDeck() {
  // Create a ref for the div element

  const cardContennt = [
    {
      id: 0,
      title: "Software Services",
      service1: "Computer/Software Setup",
      service2: "Mobile Development",
      service3: "Website and Domain Setup",
      service4: "Hardware Management",
      service5: "Email Setup",
    },
    {
      id: 1,
      title: "Education Consulting",
      service1: "Workshops for Students & Educators",
      service2: "Educational Technology Integration",
      service3: "Leadership Training Programs",
      service4: "Design Thinking Workshops",
      service5: "Tech Curriculum Design and Development",
    },
  ];

  // will target parent div for framer motion this where it will start detecting the scrolling for the cards
  const cardStartingRef = useRef(null);
  const { scrollYProgress } = useScroll({
    // which element we will need to observe
    target: cardStartingRef,
    // gets the start and end of the window
    offset: ["start end", "start start"],
  });

  // const cardPositionRef = useRef(0); // Initialize with a default value

  // card default rotate to 10 has been triggered
  // will allow card rotation to move from 0 to 10
  // const [isRotate10, setIsRotate10] = useState(false);

  // card hover trigger
  const [hover, setHover] = useState(false);

  // card rotation
  // this will move cards from 0 to 10
  // const [baseCard, setBaseCard] = useState(0);
  // const [level1Card, setlevel1Card] = useState(0);
  // const [level2Card, setlevel2Card] = useState(0);

  // card x axis movemen
  // allow cards to move along the  X axis
  // education card
  const [educationCardX, setEducationCardX] = useState(0);
  const [softwareCardX, setSoftwareCardX] = useState(0);

  // card triggers
  // this will intiate cards to allow cards to flipping once they reach 60 degrees
  // const [trigger1, setTrigger1] = useState(false);
  const [trigger2, setTrigger2] = useState(false);
  const [trigger3, setTrigger3] = useState(false);

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // const rotate2 = 9;
  // const rotate3 = 8;

  // this will reset cards 0 so they appear all stacked on each other
  const resetCardsPosition = () => {
    // setlevel1Card(0);
    // setlevel2Card(0);
    setEducationCardX(0);
    setSoftwareCardX(0);
    setTrigger2(false);
    setTrigger3(false);
  };

  // will update the card values of the overall
  // card values along the x axis
  const setCardXValues = (educationCard: number, softwareCard: number) => {
    setEducationCardX(educationCard);
    setSoftwareCardX(softwareCard);
  };

  // will cause card to rotate to 10 degrees
  // then cards will spread out to specific indexes
  // at the different positions
  // this willl be used primarly for the x values
  const setCardLevels = (position: number) => {
    const setCardXValuesPercentage = (value2: number, value3: number) => {
      const percentage2 = value2;
      const percentage3 = value3;
      setCardXValues(percentage2, percentage3);
    };

    // setCardXValuesPercentage (it card, education, software)

    if (position >= 11 && position < 20) {
      setCardXValuesPercentage(-10, 10);
    } else if (position >= 20 && position < 30) {
      setCardXValuesPercentage(-20, 20);
    } else if (position >= 30 && position < 40) {
      setCardXValuesPercentage(-30, 30);
    } else if (position >= 40) {
      setCardXValuesPercentage(-55, 55);
    }
  };

  // this function will be used to modify the y values instead
  // education card is first then
  // sofware card
  const setCardYLevels = (position: number) => {
    // console.log("y is active");
    // if (position >= 10) {
    //   setlevel1Card(0);
    //   setlevel2Card(0);
    // }

    const setCardXValuesPercentage = (value2: number, value3: number) => {
      const percentage2 = value2;
      const percentage3 = value3;
      setCardXValues(percentage2, percentage3);
    };

    // setCardXValuesPercentage (it card, education, software)

    if (position >= 11 && position < 20) {
      setCardXValuesPercentage(0, 10);
    } else if (position >= 20 && position < 30) {
      setCardXValuesPercentage(-5, 20);
    } else if (position >= 30 && position < 40) {
      setCardXValuesPercentage(-8, 50);
    } else if (position >= 40) {
      setCardXValuesPercentage(-10, 100);
    }
  };

  // based on different indexes it will update the cards and modify there position as you scroll
  const updateCardsPosition = (position: number) => {
    // console.log("Inside of updateCardsPosition");

    if (position <= 10) {
      // setIsRotate10(false);
      resetCardsPosition();
    } else {
      setHover(true);
      // setIsRotate10(true);

      if (document.documentElement.clientWidth < 1280) {
        setCardYLevels(position);
      } else {
        setCardLevels(position);
      }
    }
  };

  // the triggers will be used to start card rotation
  // if rotation goes to false then the cards rotate position will turn back to 0
  // this will trigger the to allow the transform effect to be enabled
  const updateTriggers = (position: number) => {
    const newTrigger3 = position > 43;
    const newTrigger2 = position > 45;

    setTrigger3(newTrigger3);
    setTrigger2(newTrigger2);
  };

  // card rotation will occur after certain degrees
  // by rotation it will flip from 0 defrees to 180 degrees
  // should not pass 180 degrees
  // const updateRotations = (position: number) => {
  //   const newTrigger3 = position > 66;
  //   const newTrigger2 = position > 67;

  // if (newTrigger2) {
  //   setlevel1Card(0);
  // }
  // if (newTrigger3) {
  //   setlevel2Card(0);
  // }
  // };

  // not being used as of now
  // use to update the overall content of the page
  // a version of the card effect to update the content over time
  // useing a counter method of 3 instead of percentage based
  // const updateScreenPixels = useCallback(
  //   (isIncreasing: boolean) => {
  //     let newValue = cardPositionRef.current;

  //     if (isIncreasing) {
  //       // If increasing and below 100, increment
  //       newValue = Math.min(newValue + 3, 100);
  //     } else {
  //       // If decreasing and above 0, decrement
  //       newValue = Math.max(newValue - 3, 0);
  //     }

  //     // Update the ref's current value
  //     cardPositionRef.current = newValue;

  //     // Update dependent states/functions
  //     updateCardsPosition(newValue);
  //     updateTriggers(newValue);
  //     updateRotations(newValue);
  //   },
  //   [updateCardsPosition, updateTriggers, updateRotations] // Ensure all dependencies are listed
  // );

  const openIntervalRef = useRef<number | null>(null);

  // handle the animation when scrolling
  // if scrolling is less than 10 it will reset the cards back to normal
  // else it will slowing expand cards to certain position as you scroll
  const handleScroll = useCallback(() => {
    scrollYProgress.on("change", (e) => {
      const scrolled = Math.round(e * 100);
      // console.log(scrolled);

      if (scrolled <= 10) {
        if (openIntervalRef.current) {
          clearInterval(openIntervalRef.current);
        }
        resetCardsPosition();
      } else {
        if (openIntervalRef.current) {
          clearInterval(openIntervalRef.current);
        }
        openIntervalRef.current = window.setInterval(() => {
          updateCardsPosition(scrolled);
          updateTriggers(scrolled);
          // updateRotations(scrolled);
        }, 30);
      }
    });
  }, []);

  // Initialize prevScrollTop as a ref
  useEffect(() => {
    handleScroll();
    return () => {
      if (openIntervalRef.current) {
        clearInterval(openIntervalRef.current);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    // Function to check the screen width
    const checkScreenSize = () => {
      setIsSmallScreen(document.documentElement.clientWidth < 1280);
    };

    // Initial check when the component mounts
    checkScreenSize();

    // Add event listener for window resize
    window.addEventListener("resize", checkScreenSize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, [document.documentElement.clientWidth]);

  const tranformSoftwareStyle = isSmallScreen
    ? { transform: `translate3d(0px, ${softwareCardX}%, 0px)` }
    : { transform: `translate3d(${softwareCardX}%, 0px, 0px)` };
  const tranformEducationStyle = isSmallScreen
    ? { transform: `translate3d(0px, ${educationCardX}%, 0px)` }
    : { transform: `translate3d(${educationCardX}%, 0px, 0px)` };

  return (
    <div
      ref={cardStartingRef}
      className="card-deck-center"
      // only if horizontal card feature
      // style={dynamicStyles}
    >
      <div className="card">
        {/* Education Card */}
        <div key={2} className="card-level-1" style={tranformEducationStyle}>
          <HomeCard
            id={cardContennt[0].id}
            trigger={trigger2}
            animationName="level1-MoveUpDown"
            isHover={hover}
            photo={software}
          />
        </div>
        {/* Software Card */}
        <div key={3} className="card-level-2" style={tranformSoftwareStyle}>
          <HomeCard
            id={cardContennt[1].id}
            trigger={trigger3}
            animationName="level2-MoveUpDown"
            isHover={hover}
            photo={education}
          />
        </div>
      </div>
    </div>
  );
}

export default CardAnimationDeck;
