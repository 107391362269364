import Edward from "../../images/team/edward.png";
import antwi from "../../images/team/antwi.png";
import tayshaun from "../../images/team/tayshaun.png";
import gio from "../../images/team/gio.png";
import Chillin from "../../images/team/chillen.png";
import fo from "../../images/team/fo.png";
import angel from "../../images/team/angel.png";
import einar from "../../images/team/einar.png";
import edwin from "../../images/team/edwin.png";

const innovationTeam = [
  {
    name: "Tayshaun Kelly",
    role: "Developer",
    img: tayshaun,
  },
  {
    name: "Fofang Atanga",
    role: "Developer",
    img: fo,
  },
  {
    name: "Edgar Chillin",
    role: "Developer",
    img: Chillin,
  },
  {
    name: "Edward Gonzalez",
    role: "Developer",
    img: Edward,
  },
  {
    name: "Edwin Aguilar",
    role: "Developer",
    img: edwin,
  },
  {
    name: "Einar Aguirre",
    role: "Developer",
    img: einar,
  },
  {
    name: "Angel Gomez",
    role: "Developer",
    img: angel,
  },
];

export default innovationTeam;
