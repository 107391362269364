/**
 * Represents a diamond-shaped container component used in the about page.
 */
import React from "react";
import ScrollDownTextComponent from "../ScrollDownTextComponent/ScrollDownTextComponent";
import whiteArrow from "../../../../assets/aboutPage/right_arrow_diamond_mask.svg";
import processTopSquare from "../../../../assets/aboutPage/border_box.png";
import OverlayEffectComponent from "../OverlayEffect/OverlayEffectComponent";
import "./DiamondContainer.scss";

/**
 * Interface for the DiamondContainer component props.
 */
interface DiamondContainerProps {
  rectangleRef: React.RefObject<HTMLDivElement>;
  centerTitle: string;
  topText: string;
  bottomText: string;
  ballEffectType: () => JSX.Element;
  rainEffect: boolean;
}

/**
 * Renders the DiamondContainer component.
 * @param rectangleRef - Reference to the HTMLDivElement used for scrolling.
 * @param centerTitle - The title displayed at the center of the diamond.
 * @param topText - The text displayed at the top of the diamond.
 * @param bottomText - The text displayed at the bottom of the diamond.
 * @param ballEffectType - The type of ball effect to be rendered.
 * @param rainEffect - Determines if rain effect is enabled.
 * @returns The rendered DiamondContainer component.
 */
function DiamondContainer({
  rectangleRef,
  centerTitle,
  topText,
  bottomText,
  ballEffectType,
  rainEffect,
}: DiamondContainerProps) {
  return (
    <div className="top_diamond_container">
      {/* Right text component for the top section */}
      <div id="research_text_right">
        <ScrollDownTextComponent objectRef={rectangleRef} title={topText} />
      </div>

      {/* Left text component for the top section */}
      <div id="research_text_left">
        <ScrollDownTextComponent objectRef={rectangleRef} title={topText} />
      </div>

      {/* Right text component for the bottom section */}
      <div id="planning_text_right">
        <ScrollDownTextComponent objectRef={rectangleRef} title={bottomText} />
      </div>

      {/* Left text component for the bottom section */}
      <div id="planning_text_left">
        <ScrollDownTextComponent objectRef={rectangleRef} title={bottomText} />
      </div>

      {/* Container for the ball effect, conditionally rendered based on rainEffect */}
      <div
        id={rainEffect ? "bottom_letters_container" : "top_letters_container"}
      >
        {ballEffectType()}
      </div>

      {/* Center title text */}
      <div id="client_analysis">{centerTitle}</div>

      {/* Image for the top square outline */}
      <img
        src={processTopSquare}
        alt="process_top_square"
        id="process_top_square_outline"
      />

      {/* Overlay effect component */}
      <div id="arrows_overlay">
        <OverlayEffectComponent objectRef={rectangleRef} start={0.3} />
      </div>

      {/* Top left arrow image */}
      <img src={whiteArrow} alt="top_left_arrow" id="top_left_arrow" />

      {/* Top right arrow image */}
      <img src={whiteArrow} alt="top_right_arrow" id="top_right_arrow" />

      {/* Bottom left arrow image */}
      <img src={whiteArrow} alt="bottom_left_arrow" id="bottom_left_arrow" />

      {/* Bottom right arrow image */}
      <img src={whiteArrow} alt="bottom_right_arrow" id="bottom_right_arrow" />
    </div>
  );
}

export default DiamondContainer;
