import curriculumDesignIcon from "../../images/curriculumDesignIcon.svg";
import techWorkshopsIcon from "../../images/techWorkshopsIcon.svg";
import leadershipTrainingIcon from "../../images/leadershipTrainingIcon.svg";
import designThinkingIcon from "../../images/designThinkingIcon.svg";
import educationTechnologyIntegrationIcon from "../../images/educationTechnologyIntegrationIcon.svg";
import schoolSuppliesIcon from "../../images/schoolSuppliesIcon.svg";
import techSeminarsIcon from "../../images/techSeminarsIcon.svg";
import educationalConsulting from "../../images/educationConsultingIcon.svg";

interface Service {
  id: number;
  icon: string;
  header: string;
  description: string;
}

const educationServices: Service[] = [
  {
    id: 1,
    icon: curriculumDesignIcon,
    header: "Tech Curriculum Design and Development",
    description:
      "Assisting educational institutions in designing and developing technology-focused curriculum that meet modern educational standards and industry requirements.",
  },
  {
    id: 2,
    icon: techWorkshopsIcon,
    header: "Tech Workshops for Students and Educators",
    description:
      "Conducting hands-on workshops to enhance the technology skills of both students and educators. These workshops cover various aspects of technology and its applications.",
  },
  {
    id: 3,
    icon: leadershipTrainingIcon,
    header: "Leadership Training Programs",
    description:
      "Providing leadership training to help students and educators develop essential leadership skills using technology. These programs focus on building leadership qualities through practical exercises and theoretical knowledge.",
  },
  {
    id: 4,
    icon: designThinkingIcon,
    header: "Design Thinking Workshops",
    description:
      "Offering workshops on design thinking to foster creativity and problem-solving skills using technology. These workshops guide participants through the design thinking process to tackle real-world challenges.",
  },
  {
    id: 5,
    icon: educationTechnologyIntegrationIcon,
    header: "Educational Technology Integration",
    description:
      "Consulting on the effective integration of technology into the educational environment. This includes selecting and implementing the right tools and platforms to enhance teaching and learning.",
  },
  {
    id: 6,
    icon: schoolSuppliesIcon,
    header: "Tech Clubs and Extracurricular Programs",
    description:
      "Helping schools set up and run tech clubs and extracurricular programs that engage students in technology outside the regular curriculum.",
  },
  {
    id: 7,
    icon: techSeminarsIcon,
    header: "Custom Tech Workshops and Seminars",
    description:
      "Designing and delivering customized tech workshops and seminars based on the specific needs and interests of the institution.",
  },
  {
    id: 8,
    icon: educationalConsulting,
    header: "Educational Consulting for Administrators",
    description:
      "Providing consulting services to school administrators to help them make informed decisions about technology adoption and implementation.",
  },
];

export default educationServices;
