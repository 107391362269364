import React from "react";
import "./homeCards.scss";
import CardAnimationDeck from "./CardAnimationDeck";
import HomeServiceTitle from "./HomeServiceTitle";
// import CardAnimationDeck from "./CardAnimationDeck";

function HomeCards() {
  return (
    <div className="home-cards">
      {/* <div className="home-wrapper"> */}
      <div className="home-card-service-overview">
        <HomeServiceTitle />
      </div>
      <div className="home-card-section-overview">
        <CardAnimationDeck />
      </div>
    </div>
    // </div>
  );
}

export default HomeCards;
