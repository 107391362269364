/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import "./Services.scss";
import studentsVideo from "../../assets/servicevid1.mp4";
import roboticsVideo from "../../assets/servicevid2.mp4";
import ServiceCards from "../../components/serviceCards/serviceCards";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// gets screen width and height
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

// returns screen width and height to use in main Services() function
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function Services() {
  const { width } = useWindowDimensions();
  const softwareServiceRef = useRef<HTMLDivElement | null>(null);
  const educationServiceRef = useRef<HTMLDivElement | null>(null);
  const query = useQuery();
  const getService = parseInt(query.get("tab") || "0", 2);
  const check = query.get("tab");

  // Scroll to a specific position when the URL contains the `tab` parameter and then open the pop-up
  useEffect(() => {
    if (check !== null) {
      // Check if `tab` parameter exists in the URL
      // Scroll to the top immediately
      window.scrollTo(0, 0);

      // Animate scroll down to the desired position after a short delay
      // Scroll to the appropriate section based on the `check` value
      if (check === "0") {
        setTimeout(() => {
          if (softwareServiceRef.current) {
            softwareServiceRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start", // Align to the top of the div
            });
          }
        }, 100); // Adjust delay as needed
      }

      if (check === "1") {
        setTimeout(() => {
          if (educationServiceRef.current) {
            educationServiceRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start", // Align to the top of the div
            });
          }
        }, 100); // Adjust delay as needed
      }
    }
  }, [check]);

  return (
    <div className="servicesHero">
      <div className="overviewServiceContainer">
        <p className="overviewServiceHeader">Our Services</p>
        <p className="overviewServiceDescription">
          We provide outstanding service and exceptional value to businesses for{" "}
          <span className="boldedServicesText">Software Development</span> and{" "}
          <span className="boldedServicesText">Educational Consulting.</span>
        </p>
      </div>
      {/* made 2 separate services card rather than using a map since the sliders were changing the wrong carousel if I used a map */}
      <div ref={softwareServiceRef}>
        <ServiceCards
          width={width}
          videoPlacement={width > 500 ? "90%" : "70%"}
          title="Software Development"
          video={studentsVideo}
          description="We provide tailored, secure, and optimized technology solutions, ensuring your business achieves sustained success."
        />
      </div>
      <div ref={educationServiceRef}>
        <ServiceCards
          width={width}
          videoPlacement={width > 500 ? "90%" : "70%"}
          title="Education Consulting"
          video={roboticsVideo}
          description="We provide educational consulting to enhance tech education, support educators and students, and integrate innovative solutions."
        />
      </div>
    </div>
  );
}

export default Services;
