import React from "react";
import { useNavigate } from "react-router-dom";
import arrowcircle from "../../../images/navbar2icon.svg";
import CardTextCarousel from "./CardTextCarousel";
import "./LabCards.scss";

interface CardContent {
  id: number;
  trigger: boolean;
  animationName: string;
  isHover: boolean;
  photo: string;
}

function HomeCard(props: CardContent) {
  const { id, trigger, animationName, isHover, photo } = props;

  // predifined data for the cards and carousel
  const cardContennt = [
    {
      id: 0,
      title: "Software Development",
      details:
        "We provide tailored, secure, and optimized technology solutions, ensuring your business achieves sustained success.",
      content: [
        { id: 0, service1: "Computer/Software Setup" },
        { id: 1, service1: "Mobile Development" },
        { id: 2, service1: "Website and Domain Setup" },
        { id: 3, service1: "Hardware Management" },
        { id: 4, service1: "Email Setup" },
      ],
    },
    {
      id: 1,
      title: "Education Consulting",
      details:
        "We provide educational consulting to enhance tech education, support educators and students, and integrate innovative solutions.",
      content: [
        { id: 0, service1: "Workshops for Students & Educators" },
        { id: 1, service1: "Educational Technology Integration" },
        { id: 2, service1: "Leadership Training Programs" },
        { id: 3, service1: "Design Thinking Workshops" },
        { id: 4, service1: "Tech Curriculum Design and Development" },
      ],
    },
    {
      id: 2,
      title: "IT Consulting",
      service1: "System Integration",
      service2: "IT Strategy and Planning",
      service3: "IT Support and Maintenance",
      service4: "IT Project Management",
      service5: "Network Infrastructure Setup and Management",
    },
  ];

  const navigate = useNavigate();

  const handlLearnMore = (index: number) => {
    navigate(`/services?tab=${index}`);
  };

  // const [flipQuantity, setFlipQuantity] = useState(0);

  //
  // // Handle the flip logic that will increment card flip from 0 to 180 as it increases
  // useEffect(() => {
  //   let flipTimeout: NodeJS.Timeout | undefined;

  //   if (trigger && flipQuantity <= 180) {
  //     flipTimeout = setTimeout(() => {
  //       setFlipQuantity((prev) => prev + rotate);
  //     }, 100); // Adjust the time in milliseconds for the desired speed
  //   }

  //   // Cleanup function to clear the timeout if the component unmounts or effect re-runs
  //   return () => clearTimeout(flipTimeout);
  // }, [trigger, flipQuantity, rotate]);

  return (
    <div>
      <div className={`lusion-card-wrapper ${isHover ? animationName : ""}`}>
        <div className="card">
          <div
            className="content"
            // allow card flip to occur
            style={{
              transformOrigin: "center", // Ensures the rotation is around the center
              transform: trigger ? `rotateY(180deg)` : "rotateY(0deg)",
              // "translate(-50%, -50%) rotateY(0deg)",
            }}
          >
            <div className="front">
              <div className="front-content">
                <img src={photo} alt="card" />
                {/* <div className="photo-mask" /> */}
              </div>
            </div>
            <div className="back">
              <div className="service">
                <div className="service-title">{cardContennt[id].title}</div>
                <div className="service-subcontent">
                  {cardContennt[id].details}
                </div>
                <div className="card-sub-carousel">
                  <CardTextCarousel cardInfo={cardContennt[id].content} />
                </div>
                <button
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus,
                  className="card-sub-heading"
                  type="submit"
                  // role="button" // Assign the element a 'button' role to make it interactive
                  // tabIndex={0} // Make it focusable with the keyboard
                  onClick={() => handlLearnMore(id)} // Handle the click event
                >
                  Learn More
                  <img
                    src={arrowcircle}
                    className="card-circle-svg"
                    alt="arrow up cirlce"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeCard;
