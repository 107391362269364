import mobileIcon from "../../images/tabler_device-mobile-code.svg";
import emailIcon from "../../images/emaiIcon.svg";
import computerIcon from "../../images/computerIcon.svg";
import webIcon from "../../images/webIcon.svg";
import hardwareIcon from "../../images/hardwareIcon.svg";

interface Service {
  id: number;
  icon: string;
  header: string;
  description: string;
}

const softwareServices: Service[] = [
  {
    id: 1,
    icon: mobileIcon,
    header: "Mobile Development",
    description:
      "Development of mobile applications for iOS and Android platforms. It covers the entire app development lifecycle, from concept and design to development and deployment.",
  },
  {
    id: 2,
    icon: webIcon,
    header: "Website and Domain Setup",
    description:
      "The creation and management of websites and domain names. It includes the registration of domain names, design and development of websites, and ensuring the websites are secure and optimized for performance.",
  },
  {
    id: 3,
    icon: emailIcon,
    header: "Email Setup",
    description:
      "Setting up and configuring email systems for businesses. It includes the setup of email servers, creation of user accounts, and ensuring secure and reliable email communication.",
  },
  {
    id: 4,
    icon: computerIcon,
    header: "Computer/Software Setup",
    description:
      "Setting up and configuring computers and software applications for businesses. It ensures that all hardware is properly installed and that software is configured to meet the specific needs of the organization.",
  },
  {
    id: 5,
    icon: hardwareIcon,
    header: "Hardware Management & Maintenance",
    description:
      "The management and maintenance of laptops and other hardware devices. It ensures that all hardware is functioning properly and that any issues are promptly addressed.",
  },
];

export default softwareServices;
