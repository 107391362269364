import React, { useState, useEffect, useRef } from "react";
import "./storiesExpanded.scss"; // Import styles specific to this component
import rightArrow from "../../images/arrowPointingRight.svg"; // Right arrow image for navigation
import leftArrow from "../../images/arrowPointingLeft.svg"; // Left arrow image for navigation
import Sergio from "../../images/storiesExpandedSergio.png"; // Profile image for Sergio
import Miguel from "../../images/storiesExpandedMiguel.png"; // Profile image for Miguel
import Victor from "../../images/storiesExpandedVictor.png"; // Profile image for Victor
import Maliik from "../../images/storiesExpandedMaliik.png"; // Profile image for Maliik

// Array of profile objects containing details for each story
const profiles = [
  {
    id: 1,
    name: "Sergio Perez",
    bio:
      `I am currently working as a Technical Writer at H2O.ai.\n\n` +
      `At TxT, I began by participating in several Summer Coding Leadership Academy (SCLA) programs. It was at SCLA that I wrote my first line of code, laying the foundation for my future studies in computer science. This early experience proved invaluable, eventually helping me secure a position as a Computer Science Teaching Fellow during my second year of college.\n\n` +
      `Immediately after graduating high school, I returned to TxT as a coach, a role I continued through college. During my time as a coach, I mentored and guided multiple cohorts totaling 100+ aspiring students, helping them take on roles such as product managers, project managers, UX designers, and developers. This mentorship led to the establishment and nurturing of several innovative tech startups. These startups had the opportunity to present their minimum viable products to an audience that included top-tier professionals from renowned companies and organizations like Google, A16z, Snapchat, Yahoo, GitHub, the Annenberg Foundation, Tinder, Slack, Twilio, and even elected officials from the City of Los Angeles.\n\n` +
      `I attended Hobart and William Smith Colleges (HWS), where I double-majored in Computer Science and Philosophy.\n\n` +
      `TxT has profoundly influenced my life. When I joined TxT in high school, Oscar Menjivar, the CEO and founder of the organization, mentored me. The guidance and support from the TxT community were instrumental in helping me secure the Posse Scholarship. This full-tuition merit scholarship funded my college education as a low-income student from South Los Angeles. Oscar even joined me in Geneva, New York, where Hobart and William Smith Colleges are located, to help me with orientation during my first days away from home.\n\n` +
      `After college, Oscar connected me with Rafael Coss, an H2O.ai employee, which led to my first major tech job at H2O.ai in Silicon Valley. I have been working at H2O.ai for the past four years, delving into Artificial Intelligence and Machine Learning, and growing both personally and professionally.\n\n` +
      `One of the most significant impacts of my journey with TxT is the lifelong friendship I have built with Oscar. He has been a constant source of support, helping me believe that nothing is too hard, just a lot of work and that everything is possible when we work together. As Oscar told me once, and as the saying goes, "If you want to go fast, go alone. If you want to go far, go with someone together." This philosophy has been a cornerstone of my success, and I am grateful for the opportunities and growth TxT has provided me.`,
    img: Sergio,
  },
  {
    id: 2,
    name: "Migy Dominguez",
    bio:
      "I'm currently a Senior Developer at Allure Bridals.\n\n" +
      "During the Summer Coding Leadership Academy, I helped young men of color learn and grow into self-learning members of their community. During the annual Hustle n' Code Hackathon, I help facilitate the smoothness of checking out development machines to each team.\n\n" +
      "I attended East Los Angeles College, under their Computer Science Information Technology major, working to transfer to USC. \n\n" +
      "TXT and TXT Labs both taught me ways of taking initiative in my work, and how to lead a team to a successful release.",
    img: Miguel,
  },
  {
    id: 3,
    name: "Maliik Cleveland",
    bio: `I am currently working at SpaceX in Hawthorne as a Software Engineer. My journey with Urban TXT began in the summer of 2013, when I participated in the TXT SCLA program. During my first summer, I served as the developer for my group. The following summer, I took on the role of project manager. Throughout the school year, I also attended after-school coding bootcamps organized by TXT.\n\nI pursued my higher education at UC Irvine, where I graduated with a B.S. in Computer Science. Urban TXT has significantly contributed to my development and success. They introduced me to programming and the technology industry, guiding me every step of the way. From assisting with my college applications, providing study tips and resources throughout my college years, to even helping me secure my current position at SpaceX after graduation, Urban TXT has always supported me.`,
    img: Maliik,
  },
  {
    id: 4,
    name: "Victor Lara",
    bio: "I graduated with a major in Graphic Design from California Polytechnic University. During my time at TXT and through TXT Lab, I taught middle school students coding and design programs after school. Currently, I work at IBM as a Lead UX Designer. TXT played a pivotal role in my journey into programming. In high school, I had no access to coding classes. TXT introduced me to programming, sparking my interest and inspiring me to pursue this path further.",
    img: Victor,
  },
];

// Define the props that the StoriesExpanded component expects
interface StoriesExpandedProps {
  initialProfileIndex: number; // The index of the profile to be displayed initially
}

// Main functional component to display the stories with profile details
function StoriesExpanded({ initialProfileIndex }: StoriesExpandedProps) {
  // State to track the index of the currently displayed profile
  const [currentProfileIndex, setCurrentProfileIndex] =
    useState(initialProfileIndex);

  // State to manage the fade-in animation class
  const [fadeClass, setFadeClass] = useState("fade-in");

  // State to determine if the content is scrollable
  const [isScrollable, setIsScrollable] = useState(true);

  // Reference to the content container for handling scrollability
  const contentRef = useRef<HTMLDivElement | null>(null);

  // Effect to handle window resize and adjust scrollability
  useEffect(() => {
    const handleResize = () => {
      if (contentRef.current) {
        // Check if the content height exceeds the container height
        setIsScrollable(
          contentRef.current.scrollHeight > contentRef.current.clientHeight,
        );
      }
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize); // Add event listener for resize

    return () => window.removeEventListener("resize", handleResize); // Cleanup
  }, []);

  // Effect to update the profile index when the prop changes
  useEffect(() => {
    setCurrentProfileIndex(initialProfileIndex);
  }, [initialProfileIndex]);

  // Get the current profile based on the index
  const currentProfile = profiles[currentProfileIndex];

  return (
    <div className="ParentContainer">
      <div className="WholeContainer">
        {/* Container for the profile image and navigation buttons */}
        <div className="storiesExpandedImgContainer">
          <img
            className={`storiesExpandedImg ${fadeClass}`}
            src={currentProfile.img}
            alt={currentProfile.name}
          />
        </div>
        {/* Container for profile name and bio */}
        <div className="storiesExpandedContent" ref={contentRef}>
          <p className={`storiesExpandedContentHeader ${fadeClass}`}>
            {currentProfile.name}
          </p>
          <p className={`storiesExpandedContentText ${fadeClass}`}>
            {currentProfile.bio.split("\n").map((line) => (
              <React.Fragment key={line}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </p>
        </div>
      </div>
    </div>
  );
}

export default StoriesExpanded;
