import { motion, useScroll, useTransform } from "framer-motion";
import React, { useRef } from "react";

// Props interface
interface ScrollDownTextComponentProps {
  objectRef: React.RefObject<HTMLDivElement>;
  title: string;
}

function ScrollDownTextComponent({
  objectRef,
  title,
}: ScrollDownTextComponentProps) {
  const { scrollYProgress } = useScroll({
    target: objectRef,
    offset: ["start end", "end start"],
  });

  // Transform scrollYProgress to control opacity
  const color = useTransform(scrollYProgress, [0, 1], ["#434343", "#f05a22"]); // Adjust the range [0, 1] as needed

  return (
    <motion.div
      ref={objectRef}
      style={{
        color,
      }}
    >
      {title}
    </motion.div>
  );
}

export default ScrollDownTextComponent;
