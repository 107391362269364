/* eslint-disable */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import "./homeStories.scss";
import Story from "./txterStory";
import maliik from "../../../images/storiesMaliik.png";
import sergio from "../../../images/sergio.png";
import miguel from "../../../images/storiesMiguel.png";
import victor from "../../../images/storiesVictor.png";
import arrowToStories from "../../../images/arrowToStoriesPage.svg";
import quotes from "../../../assets/CarouselQuotesImage.png";
import buttonImage from "../../../images/readMeButton.png";


// Array of TXTer stories
const txters = [
  {
    text: "working as a Technical Writer to improve A.I. processes.",
    highlightedText:
      "After working at TXT I was able to land a job at H2O.ai, ",
    name: "Sergio Perez",
    profileImage: sergio,
    readMoreLink: "/impact",
    firstStyle: "standardText",
    secondStyle: "highlightedText",
  },
  {
    text: " as a junior developer, now I am a Senior Backend Engineer.",
    highlightedText: "Thanks to TXT I was able to land a job at MedMen",
    name: "Migy Dominguez",
    profileImage: miguel,
    readMoreLink: "/impact",
    firstStyle: "standardText",
    secondStyle: "highlightedText",
  },
  {
    text: "helping me secure my first job at SpaceX.",
    highlightedText:
      "TXT supported me through college applications, providing study resources, and ",
    name: "Maliik Cleveland",
    profileImage: maliik,
    readMoreLink: "/impact",
    firstStyle: "highlightedText",
    secondStyle: "standardText",
  },
  {
    text: "I am now a senior UX Designer at IBM",
    highlightedText: "TXT prepared me to be a high performing professional. ",
    name: "Victor Lara",
    profileImage: victor,
    readMoreLink: "/impact",
    firstStyle: "standardText",
    secondStyle: "highlightedText",
  },
];

// Navigational Arrow button implementation
type ArrowProps = {
  className: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};

function NextArrow({
  className,
  style,
  onClick,
}: ArrowProps): React.ReactElement {
  return (
    <div
      className={`${className} custom-arrow next-arrow`}
      style={{ ...style }}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter" && onClick) onClick();
      }}
      aria-label="Next slide"
    >
      <FaArrowRight />
    </div>
  );
}

function PrevArrow({
  className,
  style,
  onClick,
}: ArrowProps): React.ReactElement {
  return (
    <div
      className={`${className} custom-arrow prev-arrow`}
      style={{ ...style }}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter" && onClick) onClick();
      }}
      aria-label="Previous slide"
    >
      <FaArrowLeft />
    </div>
  );
}

// Carousel Implementation
function HomeStories() {
  // For remembering the selected alumni on the impact page
  const navigate = useNavigate();

  const handleReadMoreClick = (index: number) => {
    navigate(`/impact?tab=${index}`);
  };

  const [slider, setSlider] = useState<Slider | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const settings = {
    centerMode: true,
    centerPadding: "0px",
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 20000,
    beforeChange: (current: number, next: number) => {
      const slides = document.querySelectorAll(".slick-slide");
      slides.forEach((slide) => {
        slide.classList.remove("center-slide");
      });
      slides[next + 2]?.classList.add("center-slide");
      setActiveIndex(next);
    },
  };

  const handleBarClick = (index: number) => {
    if (slider) {
      slider.slickGoTo(index);
      setActiveIndex(index);
    }
  };

  return (
    <div className="Component">
      <p className="carouselHeader">
        Our alumni <span className="carouselHeaderHighlight">success</span>{" "}
        stories
      </p>

      <div className="carouselContainer">
        <Slider ref={setSlider} {...settings}>
          {txters.map((member, index) => (
            <div key={member.name} className="slide">
              <Story
                text={member.text}
                highlightedText={member.highlightedText}
                name={member.name}
                profileImage={member.profileImage}
                readMoreLink={member.readMoreLink}
                firstStyle={member.firstStyle}
                secondStyle={member.secondStyle}
                onReadMoreClick={() => handleReadMoreClick(index)}
              />
            </div>
          ))}
        </Slider>
        <div className="animation-container">
          <PrevArrow
            className="custom-arrow"
            onClick={() =>
              (document.querySelector(".slick-prev") as HTMLElement)?.click()
            }
          />
          <div className="animated-bars">
            {txters.map((_, index) => (
              <span
                key={index}
                className={`bar ${activeIndex === index ? "active" : ""}`} // Add active class
                onClick={() => handleBarClick(index)}
                style={{ animationDelay: `${index * 1}s` }}
              >
                |
              </span>
            ))}
          </div>
          <NextArrow
            className="custom-arrow"
            onClick={() =>
              (document.querySelector(".slick-next") as HTMLElement)?.click()
            }
          />
        </div>
      </div>
    </div>
  );
}

export default HomeStories;
