import React from "react";
import "./Contact.scss";

function Contact() {
  return (
    <div className="contact">
      <h1>Contact Page</h1>
    </div>
  );
}

export default Contact;
