/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import Odometer from "react-odometerjs";
import "./NumberScoll.scss";
import useIsVisible from "./useIsVisible";

interface ScrollNumberValue {
  scrollValue: number;
}

function NumberScroll(props: ScrollNumberValue) {
  const visiblePage = useRef(null);
  const isVisible = useIsVisible({ ref: visiblePage }); // Pass ref in an object
  const { scrollValue } = props;

  const [value, setValue] = useState(1); // Initial value for the first odometer
  const [replaceValue, setReplaceValue] = useState(scrollValue - 1); // Initial value for the second odometer
  const [visible, setVisible] = useState(true); // Handles Odometer visibility
  const [replaceVisible, setReplaceVisible] = useState(false); // Handles Odometer replacement

  useEffect(() => {
    if (isVisible) {
      const timeoutId = setTimeout(() => {
        setValue(scrollValue - 1); // Sets value of first odometer to 99
        setTimeout(() => {
          setVisible(false); // Removes first odometer
          setTimeout(() => {
            setReplaceVisible(true); // Displayes second odometer
            setTimeout(() => {
              setReplaceValue(scrollValue); // sets second odometer value to 100
            });
          }, 1); // Small delay before replacing
        }, 2500); // Delay before hiding (how long countup animation lasts)
      }, 500); // Initial delay before setting value to 1 (how long until starting countup animation)

      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      setValue(1); // Reset first odometer
      setReplaceValue(scrollValue - 1); // Reset second odometer
      setVisible(true); // Reset visibility
      setReplaceVisible(false); // Reset replacement odometer visibility
    }
  }, [isVisible]);

  return (
    <div ref={visiblePage}>
      {visible && (
        <>
          <Odometer value={0} duration={3000} />
          <Odometer value={value} duration={2500} animation="count" />
        </>
      )}
      {replaceVisible && (
        <Odometer
          value={replaceValue}
          duration={1}
          animation={undefined} // Use flipping animation for flipping from 99 to 100
        />
      )}
    </div>
  );
}

export default NumberScroll;
