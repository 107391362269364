/* eslint-disable react/prop-types */
import React, { useRef } from "react";
import useIsVisible from "../../impactComponents/impactColorText/useIsVisible";
import "./homeVideo.scss";

function HomeVideo({ bgvideo }) {
  const visiblePage = useRef(null);
  const isVisible = useIsVisible({ ref: visiblePage });
  return (
    <div className="bgvidcontainer" ref={visiblePage}>
      {isVisible ? (
        <video
          preload="metadata"
          src={bgvideo}
          className="impact-vid"
          muted
          playsInline
          loop
          autoPlay
        >
          Your browser does not support the video tag.
        </video>
      ) : (
        ""
      )}
    </div>
  );
}

export default HomeVideo;
