import React from "react";
import "./homeHero2.scss";
import techguru from "../../../assets/GuyCodingVid.mp4";

function HomeHero2() {
  return (
    <div className="hero-container">
      <div className="hero-main">
        <div className="hero-video">
          <video
            className="iframe"
            src={techguru}
            playsInline
            autoPlay
            loop
            muted
          >
            Your browser does not support the video tag
          </video>
          <div className="video-overlay" /> {/* Add this div for overlay */}
        </div>
      </div>
      <div className="hero-sub-container">
        <div className="hero-sub-container-header">
          Tech Solutions with social impact
        </div>
        <div className="hero-sub-container-sub-header">
          We drive client success and empower youth. Based in South Central Los
          Angeles, we build strong communities and promote diversity in tech
          with comprehensive services.
        </div>
      </div>
    </div>
  );
}

export default HomeHero2;
