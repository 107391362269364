import React from "react";
import "./CardTextCarousel.scss";
import plus from "../../../images/ic_round-plus.svg";

interface cardSetUp {
  id: number;
  service1: string;
}

interface CardContent {
  cardInfo: cardSetUp[] | undefined;
}

function CardTextCarousel(props: CardContent) {
  const { cardInfo } = props;

  return (
    <div className="text-logo-container-card">
      <div className="text-logos-card">
        <div className="text-logos-slide-card">
          {/* render service content twice in order to prevent there being a white gap in the carousel
           * if you have rendered once than extra space will be added as items are move from intial
           * position to the end
           */}
          {cardInfo?.map((service) => {
            return (
              <div className="text-logo-content-card" key={service.id}>
                <img
                  className="text-logo-content-plus-card"
                  src={plus}
                  alt="plus"
                />
                {service.service1}
              </div>
            );
          })}
        </div>

        <div className="text-logos-slide-card">
          {cardInfo?.map((service) => {
            return (
              <div className="text-logo-content-card" key={service.id}>
                <img
                  className="text-logo-content-plus-card"
                  src={plus}
                  alt="plus"
                />
                {service.service1}
              </div>
            );
          })}
        </div>
        <div className="text-logos-slide-card">
          {cardInfo?.map((service) => {
            return (
              <div className="text-logo-content-card" key={service.id}>
                <img
                  className="text-logo-content-plus-card"
                  src={plus}
                  alt="plus"
                />
                {service.service1}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CardTextCarousel;
