import React from "react";
import "./About.scss";
import TeamSlider from "../../components/teamSlider/TeamSlider";

import AboutInnovationPartners from "../../components/aboutComponents/aboutInnovationPartners/aboutInnovationPartners";
import innovationTeam from "../../data/aboutPage/innovationTeam";
import staff from "../../data/aboutPage/staff";
import OurProcessComp from "../../components/aboutComponents/aboutOurProcess/ourProcessComp";

function About() {
  return (
    <div>
      <OurProcessComp />
      <div id="process-staff-margin" />
      <TeamSlider title="Staff" members={staff} />

      <div style={{ marginBottom: "120px" }}>
        <TeamSlider title="Innovation Team" members={innovationTeam} />
      </div>

      <div>
        <AboutInnovationPartners />
      </div>
      {/* <div>
          <HomeBottomtext />
        </div> */}
    </div>
  );
}

export default About;
