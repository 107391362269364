import React from "react";
import "./aboutInnovationPartners.scss";
import TextCarousel from "../../navbar/home-hero-features/ImageCarousel";

function AboutInnovationPartners() {
  return (
    <div className="about-partners">
      <div className="about-partners-title">Our Innovative Partners</div>
      <TextCarousel />
    </div>
  );
}

export default AboutInnovationPartners;
