import { motion, useScroll, useTransform } from "framer-motion";
import React from "react";

// Props interface
interface OverlayEffectComponentProps {
  objectRef: React.RefObject<HTMLDivElement>;
  start?: number;
  end?: number;
}

function OverlayEffectComponent({
  objectRef,
  start = 0.3,
  end = 1,
}: OverlayEffectComponentProps) {
  // const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: objectRef,
    offset: ["start end", "end start"],
  });
  const height = useTransform(scrollYProgress, [start, end], ["105%", "0%"]); // Adjust the range [0, 1] as needed
  const top = useTransform(scrollYProgress, [start, end], ["0%", "100%"]);

  return (
    <motion.div
      ref={objectRef}
      style={{
        backgroundColor: "black",
        width: "100%",
        height,
        zIndex: 1,
        opacity: 0.8,
        top,
        position: "absolute",
        left: 0,
      }}
    />
  );
}

OverlayEffectComponent.defaultProps = {
  start: 0,
  end: 0.7,
};

export default OverlayEffectComponent;
