/* eslint-disable */

import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import educationServices from "../../data/servicesPage/educationServices";
import additionalButtonPlusUnlit from "../../images/servicesCardAdditionalInfoUnlit.svg";
import additionalButtonMinusUnlit from "../../images/servicesCardAdditionalInfoLess.svg";

import "./serviceSections.scss";

type ArrowProps = {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};

interface EducationServicesProps {
  width: number;
}

const PrevArrow: React.FC<ArrowProps> = ({ className, style, onClick }) => {
  return (
    <div
      className={`${className} custom-arrow prev-arrow`}
      style={{ ...style }}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter" && onClick) onClick();
      }}
      aria-label="Previous slide"
    >
      <FaArrowLeft />
    </div>
  );
};

const NextArrow: React.FC<ArrowProps> = ({ className, style, onClick }) => {
  return (
    <div
      className={`${className} custom-arrow next-arrow`}
      style={{ ...style }}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter" && onClick) onClick();
      }}
      aria-label="Next slide"
    >
      <FaArrowRight />
    </div>
  );
};

function EducationServices(props: EducationServicesProps) {
  const { width } = props;

  const [activeCard, setActiveCard] = useState<number | null>(null);

  const [progress, setProgress] = useState(12.5);
  const [slideToShow, setSlideToShow] = useState(3);

  function displayAdditionalInfo(id: number) {
    setActiveCard(activeCard === id ? null : id);
  }

  const setSlides = () => {
    if (width <= 430) {
      setSlideToShow(1);
    } else {
      setSlideToShow(3);
    }
  };

  useEffect(() => {
    setSlides();
    window.addEventListener("resize", () => {
      setSlides();
    });
  }, []);

  const settings = {
    infinite: true,
    variableWidth: true,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    afterChange: (current: any) => {
      // Hard coded since the amount of cards are not changing dynamically
      setProgress(12.5 * (current + 1));
    },
  };

  return (
    <>
      <div className="serviceContainer" id="educationServices">
        <Slider {...settings}>
          {educationServices.map((service) => (
            <div key={service.id}
              className={`serviceCard ${activeCard === service.id ? "active" : "not-active"}`}
            >
              <img
                className={`serviceIcons ${activeCard === service.id ? "active" : "not-active"}`}
                alt="service icon"
                src={service.icon}
              />
              <div
                className={`serviceCardText ${activeCard === service.id ? "active" : "not-active"}`}
              >
                <p
                  className={`serviceHeader ${activeCard === service.id ? "active" : "not-active"}`}
                >
                  {service.header}
                </p>
                <p
                  className={`serviceInfoDescription ${activeCard === service.id ? "active" : "not-active"}`}
                >
                  {service.description}
                </p>
              </div>
              <button
                onClick={() => displayAdditionalInfo(service.id)}
                className={`noSelect serviceReadButton ${activeCard === service.id ? "active" : "not-active"}`}
                type="button"
              >
                <p className="serviceReadMoreButtonText">
                  {activeCard === service.id ? "Read Less" : "Read More"}
                </p>
                <img
                  className={`serviceCardAdditionalButtonImg ${activeCard === service.id ? "active" : "not-active"}`}
                  alt="additional info button"
                  src={
                    activeCard === service.id
                      ? additionalButtonMinusUnlit
                      : additionalButtonPlusUnlit
                  }
                />
              </button>
            </div>
          ))}
        </Slider>
      </div>
      <div className="serviceCardButtons">
        <PrevArrow
          className="custom-arrow"
          onClick={() =>
            (
              document.querySelector(
                "#educationServices .slick-prev",
              ) as HTMLElement
            )?.click()
          }
        />
        <NextArrow
          className="custom-arrow"
          onClick={() =>
            (
              document.querySelector(
                "#educationServices .slick-next",
              ) as HTMLElement
            )?.click()
          }
        />
        <div className="serviceProgressBar">
          <div className="serviceProgressMade" style={{ width: `${progress}%` }} />
        </div>
      </div>
    </>
  );
}

export default EducationServices;