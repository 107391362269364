import React from "react";
import ImpactText from "./ImpactText";
import NumberScroll from "./NumberScroll";
import "./impactTextStack.scss";

function ImpactTextStack() {
  const impactContent = [
    {
      id: 0,
      score: 1000,
      description: "Hours of Coding instruction",
      sign: "+",
    },
    { id: 1, score: 95, description: "Attend 4-year universities", sign: "%" },
    { id: 2, score: 75, description: "Major in Computer Science", sign: "%" },
  ];
  return (
    <div className="impact-text-container">
      <div className="impact-text-color-text">
        <ImpactText />
      </div>
      <div className="number-container">
        <div className="number-scroll">
          {impactContent.map((info) => {
            return (
              <div key={info.id}>
                <div className="number-scroll-item">
                  <NumberScroll scrollValue={info.score} />
                  <span className="number-text-module">{info.sign}</span>
                </div>
                <div className="number-text-desc">{info.description}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ImpactTextStack;
