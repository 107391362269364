/* eslint-disable */
import React, { useState } from "react";
import Slider from "react-slick";
import "./TeamSlider.scss";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

type ArrowProps = {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};

const NextArrow: React.FC<ArrowProps> = ({ className, style, onClick }) => {
  return (
    <div
      className={`${className} custom-arrow next-arrow`}
      style={{ ...style }}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter" && onClick) onClick();
      }}
      aria-label="Next slide"
    >
      <FaArrowRight />
    </div>
  );
};

const PrevArrow: React.FC<ArrowProps> = ({ className, style, onClick }) => {
  return (
    <div
      className={`${className} custom-arrow prev-arrow`}
      style={{ ...style }}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter" && onClick) onClick();
      }}
      aria-label="Previous slide"
    >
      <FaArrowLeft />
    </div>
  );
};

interface TeamSliderProps {
  title: string;
  members: {
    name: string;
    role: string;
    img: string;
  }[];
}

const TeamSlider = ({ title, members }: TeamSliderProps) => {
  const [slider, setSlider] = useState<Slider | null>(null);
  const [centerSlide, setCenterSlide] = useState<number>();

  const getClassName = (index: number, centerSlide: number): string => {
    if (index === centerSlide) {
      return "middleBar";
    } else {
      return "bar";
    }
  };

  const settings = {
    centerMode: true,
    centerPadding: "70px",
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    // variableWidth: true,
    responsive: [
      {
        breakpoint: 1728,
        settings: {
          slidesToShow: 5,
          centerPadding: "120px",
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          centerPadding: "120px",
        },
      },
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 3,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 600, // New breakpoint for viewports between 390px and 834px
        settings: {
          slidesToShow: 1, // Adjust the number of slides to show as needed
          centerPadding: "40px", // Adjust padding as needed
        },
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    beforeChange: (current: number, next: number) => {
      setCenterSlide(next);
      // const slides = document.querySelectorAll(".slick-slide");
      // slides.forEach((slide) => {
      //   slide.classList.remove("center-slide");
      // });
      // slides[next + 2]?.classList.add("center-slide");
    },
  };

  const handleBarClick = (index: number) => {
    if (slider) {
      slider.slickGoTo(index);
    }
  };

  return (
    <div className="slider-container">
      <p className="slider-title">{title}</p>
      <Slider ref={setSlider} {...settings}>
        {members.map((member, index) => (
          <div key={member.name} className="team-slide">
            <img src={member.img} alt={member.name} className="slide-img" />

            {centerSlide === index && (
              <div className="name-role-container">
                <p className="name">{member.name}</p>
                <p className="team-role">{member.role}</p>
              </div>
            )}
          </div>
        ))}
      </Slider>
      <div className="bars-animation-container">
        <PrevArrow
          className="custom-arrow"
          onClick={() => slider?.slickPrev()}
        />
        <div className="animated-bars">
          {members.map((_, index) => (
            <span
              key={index}
              className={getClassName(index, centerSlide || 0)}
              onClick={() => {
                handleBarClick(index);
              }}
              style={{ animationDelay: `${index * 1}s` }}
            >
              |
            </span>
          ))}
        </div>
        <NextArrow
          className="custom-arrow"
          onClick={() => slider?.slickNext()}
        />
      </div>
    </div>
  );
};

export default TeamSlider;
