import gio from "../../images/team/gio.png";
import adrianR from "../../assets/aboutPage/staff/adrianR_labs.jpg";
import bryanZ from "../../assets/aboutPage/staff/bryanZ_labs.jpg";
import johnY from "../../assets/aboutPage/staff/johnYoo_labs.jpg";
import oscar from "../../assets/aboutPage/staff/oscarv2.jpg";
import antwi from "../../assets/aboutPage/staff/antwi_labs2.jpg";

const staff = [
  {
    name: "ANTWI DWOBENG MANU",
    role: "Technology Product Associate",
    img: antwi,
  },

  {
    name: "Bryan Zamora",
    role: "Software Engineer",
    img: bryanZ,
  },
  {
    name: "Adrian Reynoso",
    role: "Innovation Coach",
    img: adrianR,
  },
  {
    name: "John Yoo",
    role: "Software Associate Lead",
    img: johnY,
  },
  {
    name: "Gio Lopez",
    role: "UX/UI Designer",
    img: gio,
  },
  {
    name: "Oscar Menjivar",
    role: "CEO / Founder",
    img: oscar,
  },
];

export default staff;
