import React from "react";
import "./GivingBackBanner.scss";

function GivingBackBanner() {
  return (
    <div className="giving-back">
      <div className="giving-back-header">Giving Back</div>
      <div className="giving-back-sub">
        Our commitment extends beyond business success; All profits are
        reinvested into the community through our nonprofit,{" "}
        <span className="giving-back-sub-orange">
          URBAN TXT:Teens Exploring Technology.
        </span>
      </div>
    </div>
  );
}

export default GivingBackBanner;
