import React from "react";
import "./serviceCards.scss";
import SoftwareServices from "../serviceSections/softwareServices";
import EducationServices from "../serviceSections/educationServices";

interface OverallCardContent {
  title: string;
  video: string;
  description: string;
  videoPlacement: string;
  width: number;
}

function ServiceCards(props: OverallCardContent) {
  const { title, video, description, videoPlacement, width } = props;
  const educationVideo =
    title === "Education Consulting" ? "educationVideo" : "";

  return (
    <>
      <div className="servicesTopContainer">
        <div className="videoContainer">
          <video
            className={`serviceVideo ${educationVideo}`}
            src={video}
            style={{ top: videoPlacement }}
            muted
            playsInline
            loop
            autoPlay
          />
          <div className="cardTitleContainer">
            <p className="cardTitle">{title}</p>
            <p className="cardDescription">{description}</p>
          </div>
        </div>
      </div>
      {title === "Software Development" ? (
        <div className="softwareCardBottom">
          <SoftwareServices width={width} />
        </div>
      ) : (
        <div className="educationCardBottom">
          <EducationServices width={width} />
        </div>
      )}
    </>
  );
}

export default ServiceCards;
