import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import "./ImpactText.scss";
import ImpactGradient from "../../../images/impact_nongradient.svg";
import ImpactNonGradient from "../../../images/impact_gradient.svg";
import subtract from "../../../images/Subtract-transparent.svg";
import useIsVisible from "./useIsVisible";
import FluidAnimation from "./FluidAnimation";

// Memoize FluidAnimation to prevent unnecessary re-renders
const MemoizedFluidAnimation = React.memo(FluidAnimation);

export default function ImpactText() {
  const visiblePage = useRef(null);
  const isVisible = useIsVisible({ ref: visiblePage });
  const [isHovered, setIsHovered] = useState(false);
  const [showFluidAnimation, setShowFluidAnimation] = useState(false);
  const [hoverTimer, setHoverTimer] = useState<NodeJS.Timeout | null>(null);

  const size = 300;
  const [count, setCount] = useState(0);
  const [countCircle2, setCountCircle2] = useState(70);

  useEffect(() => {
    const frameRate = 30; // Adjust frame rate for smooth animation
    const interval = setInterval(() => {
      setCount((prevCount) => (prevCount >= 120 ? 0 : prevCount + 0.2));
    }, 1000 / frameRate); // Reduce frame rate to 30 FPS

    const interval2 = setInterval(() => {
      setCountCircle2((prevCount) => (prevCount >= 120 ? 0 : prevCount + 0.2));
    }, 1000 / frameRate); // Reduce frame rate to 30 FPS

    return () => {
      clearInterval(interval);
      clearInterval(interval2);
    };
  }, []);

  const handleMouseEnter = () => {
    // Start a timer to show the fluid animation after a delay
    const timer = setTimeout(() => {
      setShowFluidAnimation(true);
    }, 300); // Delay for 300ms before loading the FluidAnimation

    setHoverTimer(timer);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    // Clear the timer if the user leaves before the delay is over
    if (hoverTimer) {
      clearTimeout(hoverTimer);
      setHoverTimer(null);
    }
    setShowFluidAnimation(false); // Hide the fluid animation when hover is removed
    setIsHovered(false);
  };

  return (
    <div className="main">
      {count < 120 && (
        <motion.div
          className="mask"
          animate={{
            WebkitMaskPosition: `${count}%`,
            WebkitMaskSize: `${size}px`,
          }}
          transition={{
            WebkitMaskPosition: {
              type: "tween",
              ease: "backOut",
              duration: 0.8,
            },
            WebkitMaskSize: { type: "tween", ease: "backOut", duration: 0.5 },
          }}
        >
          <div>
            <img width="100%" src={ImpactGradient} alt="impact_gradient" />
          </div>
        </motion.div>
      )}

      {countCircle2 < 120 && (
        <motion.div
          className="mask"
          animate={{
            WebkitMaskPosition: `${countCircle2}%`,
            WebkitMaskSize: `${size}px`,
          }}
          transition={{
            WebkitMaskPosition: {
              type: "tween",
              ease: "backOut",
              duration: 0.8,
            },
            WebkitMaskSize: { type: "tween", ease: "backOut", duration: 0.5 },
          }}
        >
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <img width="100%" src={ImpactGradient} alt="impact_gradient" />
          </div>
        </motion.div>
      )}

      <div
        className="body"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div>
          <img width="100%" src={ImpactNonGradient} alt="impact_non_gradient" />
        </div>
      </div>

      <div className="background-image">
        {/* <img  src={subtract} alt="transparent our impact content" /> */}
      </div>

      <div className="fluid-cursor-content" ref={visiblePage}>
        {/* <FluidMouse isHovered={isHovered} /> */}
        <div
          className={`fluid-cursor-content-delay ${isHovered ? "visible" : ""}`}
        >
          {/* <FluidAnimation/> */}
        </div>
        {/* {isVisible ? (
          <div
            className={`fluid-cursor-content-delay ${isHovered ? "visible" : ""}`}
            style={{
              opacity: showFluidAnimation ? 1 : 0,
              transition: "opacity 1s ease-in-out", // Smooth opacity transition
            }}
          > */}
        {/* Render the FluidAnimation only after the hover delay */}
        {/* {showFluidAnimation && <MemoizedFluidAnimation />} */}
        {/* </div>
        ) : null} */}
      </div>
    </div>
  );
}
