import React from "react";
import "./Impact.scss";
import vid from "../../assets/impactvid2.mp4";
import ImpactImages from "../../components/impactComponents/impactImages/ImpactImages.jsx";
import HomeVideo from "../../components/homeComponents/homeVideo/HomeVideo.jsx";
import ImpactAlumniImpact from "../../components/impactComponents/ImpactAlumniImpact/ImpactAlumniImpact";
import HeroImpactBanner from "../../components/impactComponents/ImpactBanners/HeroImpactBanner";
import GivingBackBanner from "../../components/impactComponents/ImpactBanners/GivingBackBanner";
import ImpactTextStack from "../../components/impactComponents/impactColorText/ImpactTextStack";

function Impact() {
  return (
    <div className="impact">
      <div>
        <HeroImpactBanner />
      </div>{" "}
      <div>
        <ImpactAlumniImpact />
      </div>
      <div>
        <GivingBackBanner />
      </div>
      <div className="impactbgv">
        <HomeVideo bgvideo={vid} />
      </div>
      <div>
        <ImpactImages />
      </div>
      <div>
        <ImpactTextStack />
      </div>
    </div>
  );
}

export default Impact;
