import React from "react";
import "./Home.scss";
import HomeCards from "../../components/homeComponents/homeCards/homeCards";
import HomeBottomtext from "../../components/homeComponents/homeBottomtext/homeBottomtext";
import HomeHero2 from "../../components/homeComponents/homeHero/homeHero2";
import HomeTitleComponents from "../../components/homeComponents/homeTitleComponents/HomeTitleComponents";
import HomeStories from "../../components/homeComponents/homeStories/homeStories";

function Home() {
  return (
    <div>
      <HomeHero2 />
      <div>
        <HomeCards />
      </div>
      <div>
        <HomeTitleComponents />
      </div>
      <div>
        <HomeStories />
      </div>

      <div>
        <HomeBottomtext />
      </div>
    </div>
  );
}

export default Home;
