import React, { useState, useEffect } from "react";
import "./impactImages.scss";
import image1 from "../../../assets/Youth1.png";
import image2 from "../../../assets/Youth2.png";
import image3 from "../../../assets/Youth3.png";

function ImpactImages() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const openNewWindow = () => {
    window.open("https://urbantxt.org/home", "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    // Function to check the screen width
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 390);
    };

    // Initial check when the component mounts
    checkScreenSize();

    // Add event listener for window resize
    window.addEventListener("resize", checkScreenSize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, [window.innerWidth]);

  const impactImageContainer = isSmallScreen ? "" : "impactImgContainer";

  return (
    <div className={impactImageContainer}>
      <div className="impactTextContainer">
        <div className="impactText">
          <p className="txtGivingDesc">
            <span className="txtGivingDesc-orange">URBAN TXT</span> runs Coding
            Leadership Academies, offering inner-city youth the opportunity to
            learn, grow, and become the next generation of tech leaders.
          </p>
        </div>
        <button
          className="learnMore"
          type="submit"
          onClick={() => openNewWindow()}
        >
          <p className="learnMoretxt">Learn More</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="learnMoretxt-img"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            border="none"
          >
            <circle cx="16" cy="16" r="16" fill="#060606" />
            <path
              d="M21.2426 11.2426C21.4036 11.4037 21.4928 11.6233 21.4906 11.8532L21.4212 19.2084C21.4152 19.4357 21.3201 19.6532 21.1565 19.8141C20.9928 19.9749 20.7738 20.0662 20.5465 20.0684C20.3192 20.0705 20.1019 19.9833 19.9413 19.8255C19.7807 19.6677 19.6897 19.452 19.6879 19.2248L19.7375 13.9621L12.569 21.1306C12.4049 21.2947 12.1836 21.3881 11.9537 21.3902C11.7238 21.3924 11.5042 21.3032 11.3431 21.1421C11.1821 20.9811 11.0929 20.7615 11.095 20.5316C11.0972 20.3017 11.1906 20.0804 11.3547 19.9163L18.5232 12.7478L13.2605 12.7974C13.0333 12.7956 12.8175 12.7046 12.6598 12.544C12.502 12.3834 12.4148 12.166 12.4169 11.9388C12.4191 11.7115 12.5104 11.4924 12.6712 11.3288C12.8321 11.1652 13.0496 11.0701 13.2768 11.064L20.6321 10.9947C20.862 10.9925 21.0816 11.0817 21.2426 11.2426Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      {}
      {isSmallScreen ? (
        ""
      ) : (
        <div className="alumni">
          <img className="alumImg" src={image2} alt="img1" />
          <img className="alumImg" src={image1} alt="img1" />
          <img className="alumImg" src={image3} alt="img1" />
        </div>
      )}
    </div>
  );
}

export default ImpactImages;
