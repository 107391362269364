import React, { useRef } from "react";
import "./ourProcessComp.scss";
import BallEffect from "./BallEffect/BallEffect";

import circle01 from "../../../assets/aboutPage/01_circle.png";
import circle02 from "../../../assets/aboutPage/02_circle.png";
import circle03 from "../../../assets/aboutPage/03_circle.png";
import RainBallEffectComponent from "./RainBallEffectComponent/RainBallEffectComponent";
import DiamondContainer from "./DiamondContainer/DiamondContainer";

function OurProcessComp() {
  const topRectagleRef = useRef(null);
  const bottomRectagleRef = useRef(null);

  return (
    <div className="mainContainer">
      <div className="subContainer1">
        <div className="Title">About Us</div>
        <div className="Description">
          Our process allows us to provide exceptional services, while also
          empowering youth with invaluable tech, business, design, and many more
          skills.
        </div>
      </div>

      <div>
        <div className="proposalTitle">PROJECT PROPOSAL</div>

        <div>
          <img src={circle01} alt="01_circle" id="firstCircle" />

          <div id="top-dimaond-container">
            <DiamondContainer
              rectangleRef={topRectagleRef}
              centerTitle="CLIENT ANALYSIS"
              topText="Research"
              bottomText="Planning"
              ballEffectType={BallEffect}
              rainEffect={false}
            />
          </div>
        </div>
        <img src={circle02} alt="02_circle" id="secondCircle" />

        <div id="bottom-diamond-container">
          <DiamondContainer
            rectangleRef={bottomRectagleRef}
            centerTitle="DELIVERABLES"
            topText="Develop"
            bottomText="Test & Revision"
            ballEffectType={RainBallEffectComponent}
            rainEffect
          />
        </div>
        <img src={circle03} alt="03_circle" id="thirdCircle" />
        <div className="endProduct">END PRODUCT</div>
      </div>
    </div>
  );
}

export default OurProcessComp;
