import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./impactAlumniImpact.scss";
import StoriesExpanded from "../../storiesExpanded/StoriesExpanded";
import miguel from "../../../images/miggy.jpeg";
import malik from "../../../images/Malik.png";
import sergio from "../../../images/sergio.png";
import victor from "../../../images/victor.png";
import medManImg from "../../../images/MedMenImg.png";
import spacexImg from "../../../images/spaceXImg.png";
import h2oaiImg from "../../../images/H20AIImg.png";
import ibmImg from "../../../images/IBMImg.png";
import cancel from "../../../images/xCancel.png";
import readMoreButton from "../../../images/readMoreButton.svg";

// Function to parse query parameters
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const tabs = [
  {
    name: "Sergio Perez",
    position: "Technical Writer",
    companyImg: h2oaiImg,
    bioImg: sergio,
    bio: "I am currently working as a Technical Writer at H2O.ai. At TxT, I began by participating in several Summer Coding Leadership Academy (SCLA) programs...",
    imgClass: "sergioImg",
  },
  {
    name: "Migy Dominguez",
    position: "Senior Backend Engineer",
    companyImg: medManImg,
    bioImg: miguel,
    bio: "I'm currently a Senior Developer at Allure Bridals. During the Summer Coding Leadership Academy, I helped young men of color learn and grow...",
    imgClass: "miguelImg",
  },
  {
    name: "Maliik Cleveland",
    position: "Software Engineer",
    companyImg: spacexImg,
    bioImg: malik,
    bio: "I am currently working at SpaceX in Hawthorne as a Software Engineer. My journey with Urban TXT began in the summer of 2013...",
    imgClass: "malikImg",
  },
  {
    name: "Victor Lara",
    position: "Lead UX Designer",
    companyImg: ibmImg,
    bioImg: victor,
    bio: "I graduated with a major in Graphic Design from California Polytechnic University. During my time at TXT and through TXT Lab...",
    imgClass: "victorImg",
  },
];

function ImpactAlumniImpact() {
  const query = useQuery();
  const initialTab = parseInt(query.get("tab") || "0", 10); // Get the tab index from URL
  const [selectedTab, setSelectedTab] = useState(initialTab);
  const [isActive, setIsActive] = useState(false);
  const check = query.get("tab"); // Get the tab parameter from the URL to CHECK if window scroll occurs

  useEffect(() => {
    setSelectedTab(initialTab); // Set the initial selected tab when the component mounts
  }, [initialTab]);

  // Scroll to a specific position when the URL contains the `tab` parameter and then open the pop-up
  useEffect(() => {
    if (check !== null) {
      // Check if `tab` parameter exists in the URL
      // Scroll to the top immediately
      window.scrollTo(0, 0);

      // Animate scroll down to the desired position after a short delay
      setTimeout(() => {
        window.scrollTo({
          top: 450, // Scroll to 450px from the top
          behavior: "smooth", // Enable smooth scrolling
        });

        // After the scroll, open the pop-up
        setTimeout(() => {
          setIsActive(true);
          document.body.style.overflow = "hidden"; // Disable scrolling when pop-up is active
        }, 1000); // Delay to ensure the scroll completes before the pop-up opens
      }, 100); // Adjust the delay as needed (100ms in this example)
    }
  }, [check]);

  const handleButtonClick = () => {
    setIsActive(!isActive);
    if (!isActive) {
      document.body.style.overflow = "hidden"; // Disable scrolling when pop-up is active
    } else {
      document.body.style.overflow = "auto"; // Re-enable scrolling when pop-up is closed
    }
  };

  return (
    <div className="WholeComponent">
      <p className="Header">Our alumni success stories</p>
      <p className="Subheader">
        Learn about their experiences with TXT that led to their roles in top
        tech companies.
      </p>
      <div className={`componentContainer ${isActive ? "blurBackground" : ""}`}>
        <div className="parentContainer">
          <div className="subContainer23">
            <div className="alumniImgContainer">
              <button
                className="extendStoriesButton"
                type="button"
                onClick={handleButtonClick}
              >
                <img
                  src={tabs[selectedTab].bioImg}
                  alt="Alumni"
                  className={tabs[selectedTab].imgClass}
                />
              </button>
            </div>
            <div className="alumniBioContainer">
              <p className="bioPeak">{tabs[selectedTab].bio}</p>
              <button
                className="extendStoriesButton"
                type="button"
                id="readMoreButton"
                onClick={handleButtonClick}
              >
                <p className="readMoreButtonContent">Read More</p>
                <img
                  alt="read more icon"
                  src={readMoreButton}
                  className="readMoreButtonContent"
                  id="readMoreButtonIcon"
                />
              </button>
            </div>
          </div>
          <div className="subContainer11">
            {tabs.map((tab, index) => {
              // Determine the className for special images
              const companyImgClass =
                tab.companyImg === spacexImg || tab.companyImg === medManImg
                  ? "specialImageClass"
                  : "";

              // Determine if this tab should have a special class based on position
              const positionClass =
                tab.position === "Senior Backend Engineer" ||
                tab.position === "Software Engineer"
                  ? "specialTabPosition"
                  : "";

              const backendEngineerPosition =
                tab.position === "Senior Backend Engineer"
                  ? "backendEngineerPosition"
                  : "";

              return (
                <button
                  key={tab.name}
                  type="button"
                  className={`tab ${selectedTab === index ? "active" : ""}`}
                  onClick={() => setSelectedTab(index)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") setSelectedTab(index);
                  }}
                  tabIndex={0}
                >
                  <div
                    className={`tabName ${selectedTab === index ? "active" : ""}`}
                  >
                    <p>{tab.name}</p>
                  </div>
                  <div
                    className={`tabPosition ${selectedTab === index ? "active" : ""} ${positionClass} ${backendEngineerPosition}`}
                  >
                    <p>{tab.position}</p>
                  </div>
                  <div className={`tabImage ${companyImgClass}`}>
                    <img src={tab.companyImg} alt={`${tab.name}'s company`} />
                  </div>
                </button>
              );
            })}
          </div>
        </div>

        {/* Modal Overlay and Pop-up */}
        {isActive && (
          <div className="modalOverlay">
            <button
              id="cancelButtonMain"
              type="button"
              onClick={handleButtonClick}
            >
              <img alt="x button" src={cancel} />
            </button>
            <div className="popup">
              <StoriesExpanded initialProfileIndex={selectedTab} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ImpactAlumniImpact;
