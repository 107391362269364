import React from "react";
import "./homeStories.scss";
import quotes from "../../../assets/CarouselQuotesImage.png";
import buttonImage from "../../../images/readMeButton.png";

interface StoryProps {
  text: string;
  highlightedText: string;
  name: string;
  profileImage: any; // Assuming it's a URL to the image
  readMoreLink: string;
  firstStyle: string; // Dictates if highlight comes first or second
  secondStyle: string;
  onReadMoreClick: () => void; // Add this line
}

function Story({
  text,
  highlightedText,
  name,
  profileImage,
  readMoreLink,
  firstStyle,
  secondStyle,
  onReadMoreClick, // Add this prop to the function parameters
}: StoryProps) {
  return (
    <div className="storyContainer">
      <div className="textBlock">
        <img src={quotes} alt="Quotes" className="quotes" />
        <div className="text-with-button">
          <p className={firstStyle}>
            <span className={secondStyle}>{highlightedText}</span>
            {text}
          </p>
          <p className="standardText">{name}</p>
        </div>
        <button
          type="button"
          className="read-more-button"
          onClick={onReadMoreClick}
        >
          Read More
          <img src={buttonImage} alt="Icon" className="button-icon" />
        </button>
      </div>
      <div className="right">
        <img src={profileImage} alt={name} className="profileImage" />
      </div>
    </div>
  );
}

export default Story;
